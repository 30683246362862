import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BingHero from '../components/bing/bing-hero'
import BingContent from '../components/bing/bing-content'

const Features = () => {
  return (
    <Layout>
      <SEO title='Bing Ad Credits' pageType='features' flowType='none' />
      <BingHero />
      <BingContent />
    </Layout>
  )
}

export default Features
