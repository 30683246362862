import React from 'react'
import HeroBg from '../../../images/features-hero.jpg'
import Hero from '../../feature/feature-hero/feature-hero.styles'

const BingHero = () => (
  <Hero background={HeroBg}>
    <Hero.contentContainer>
      <Hero.title>Bing Ad Credits</Hero.title>
    </Hero.contentContainer>
  </Hero>
)

export default BingHero
